import { Form, Input, Button, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CheckBaoGia.css';

export default function CheckBaoGia(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const language = localStorage.getItem('language');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFinishModal = () => {
    setIsModalVisible(false);
    navigate('/kiemtradonhang');
  };

  const handleSearchOrder = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/order-code/check?code=${value.orderCode}&phone=${value.phoneNumber}`);

      if (!res.ok) { 
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const order = await res.json();

      if (order.status === 1) {
        if (order.data?.comboContract?.contract_list?.length) {
          props.setOrderDetail(order.data.comboContract);
          props.setType(true);
          navigate(`/kiemtradonhang/${value.orderCode}`);
        } 
        else if (order.data?.order_airport) {
          props.setOrderDetail(order.data.order_airport);
          props.setType(false);
          navigate(`/kiemtradonhang/${value.orderCode}`);
        } 
        else {
          setIsModalVisible(true);
        }
      } 
      else {
        setIsModalVisible(true);
      }
    } catch (error) {
      console.error("Lỗi khi gọi API:", error);
      setIsModalVisible(true);
    }
  };

  return (
    <div className="checkdon">
      <div className="check-content">
        <h1 className='head-label'>
          {language === '1' ? 'Check Order' : 'Kiểm tra đơn hàng'}
        </h1>

        <Form
          form={form}
          name="check-order"
          onFinish={handleSearchOrder}
          layout="vertical"
          className='check-form'
        >
          <h4 className='label'>{language === '1' ? 'Order Code' : 'Mã đơn hàng'}</h4>
          <Form.Item
            name="orderCode"
            className='form-input'
            initialValue={props.orderCode || ""}
            rules={[
              { required: true, message: 'Cần nhập mã đơn hàng' },
              { pattern: /^[0-9]{11,12}$/, message: 'Mã đơn hàng chỉ có thể chứa số' }
            ]}
          >
            <Input className='check-input' type='text' placeholder='Mã đơn hàng' inputMode="numeric" />
          </Form.Item>

          <h4 className='label'>{language === '1' ? 'Phone Number' : 'Số điện thoại'}</h4>
          <Form.Item
            name="phoneNumber"
            className='form-input'
            rules={[
              { required: true, message: 'Cần nhập số điện thoại' },
              { pattern: /^[0-9]{10,11}$/, message: 'Số điện thoại không hợp lệ' }
            ]}
          >
            <Input className='check-input' type='text' placeholder='Số điện thoại đặt hàng' inputMode="numeric"/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" 
              className='form-submit-button heading-4 check-button'>
              {language === '1' ? 'Check Order' : 'Xác nhận'}
            </Button>
          </Form.Item>
        </Form>

        <Modal
          title={null}
          open={isModalVisible}
          onCancel={handleFinishModal}
          width={500}
          closable={false}
          footer={null}
          centered={true}
          className="contact-modal"
          mask={true}
          maskStyle={{ backgroundColor: "white", top: '64px' }}
          style={{ backgroundColor: 'transparent', marginTop: `64px` }}
          bodyStyle={{ padding: 0 }}
          wrapClassName="transparent-modal-wrapper"
          getContainer={() => document.querySelector('.check-content')}
        >
          <div className="finish-icon">
            <img src="Image/BookingPage/Icon/Group 15 (2).png" alt="finish-icon" />
          </div>
          <h2 className="success">Xin lỗi !</h2>
          <p className="text-finish">Mã đơn hàng hoặc số điện thoại không đúng</p>
          <Button onClick={handleFinishModal} className="confirm-button confirm-button-error">
            OK
          </Button>
        </Modal>
      </div>
    </div>
  );
}
