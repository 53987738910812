import {
    payment_method,
    payment_status,
    ServiceList,
} from '../data';
import { useState } from 'react';
import { useEffect } from 'react';
export default function PaymentInfo(props) {
    const [totalCK, setTotalCK] = useState(0);
    const [qrVisible, setQrVisible] = useState(false);
    const FormatMoney = (money) => {
        let formatMoney = money.toLocaleString('vi', { style: 'currency', currency: 'VND' }).slice(0, -2)
        return formatMoney;
    }
    const pay = props.data?.payments;
    useEffect(() => {
        if (!pay) return;
        // kiem tra loai thanh toasn full
        if (pay.type === 1 || pay.type === 2) {
            // neu laf chuyen khoan
            if (pay.stage[0].payment_method === 1) {
                // nếu đã thanh toán
                if (pay.stage[0].payment_status === 1) {
                    setQrVisible(false);
                } else {// chưa thì hiện qr
                    setQrVisible(true);
                    setTotalCK(pay.stage[0].amount);
                }
            } else setQrVisible(false);
        } else if (pay.type === 3) {
            // nêu cả 2 lần đều tiền mặt
            if (pay.stage[0].payment_method === 0 && pay.stage[1].payment_status === 0) {
                setQrVisible(false);
            } else {
                // nếu lần 1 là chuyển khoản
                if (pay.stage[0].payment_method === 1) {
                    if (pay.stage[0].payment_status === 1) {
                        setQrVisible(false);
                        // kiem tra tiếp lần 2
                        if (pay.stage[1].payment_method === 1) {
                            if (pay.stage[1].payment_status === 1) {
                                setQrVisible(false);
                            } else {
                                setQrVisible(true);
                                setTotalCK(pay.stage[1].amount);
                            }
                        }
                    } else {
                        setQrVisible(true);
                        setTotalCK(pay.stage[0].amount);
                    }
                } else if (pay.stage[1].payment_method === 1) {
                    if (pay.stage[1].payment_status === 1) {
                        setQrVisible(false);
                    } else {
                        setQrVisible(true);
                        setTotalCK(pay.stage[1].amount);
                    }
                }
            }
        }
    }, [props.data]);
    
    // const total = props.data?.payments?.stage.reduce((a, b) => a + b.amount, 0);
    const linkIMGQR = `https://img.vietqr.io/image/vietcombank-0451000327817-qr_only.jpg?amount=${totalCK}&addInfo=${props.data.combo_code}&accountName=TRAN%NGOC%HIEU`;
    
    return (
        <>
            <div className="payment-info">
                <h1 className="head-label" style={{'margin-top':'68px'}}>Thanh toán và ghi chú</h1>
                <p className="label-payment">Mã đơn hàng: {props.data.combo_code}</p>
                <p className='left-label'>
                    {props.data?.payments?.type === 1 ? 'Thanh toán trả trước 100%' :
                        props.data?.payments?.type ===2 ? 'Thanh toán trả sau 100%' :
                            props.data?.payments?.type === 3 ? 'Thanh toán trả nhiều lần' : ''}
                    {
                        props.data?.payments?.vat === 1 ?
                            ` - có hoá đơn` : ''
                    }
                </p>
                <div className='text-box-pay'>
                    {props.data?.payments?.stage?.map((e, index) =>
                        <div key={index}>
                            <p className="left-text">Lần {index + 1}: {FormatMoney(e.amount)} VNĐ - {payment_method[e.payment_method]} - {payment_status[e.payment_status]}</p>
                        </div>
                    )}
                </div>
                {qrVisible?
                <>
                <p className='left-label'>Thông tin chuyển khoản</p>
                <div className="text-box-pay2">
                    <div className="text-content-pay">
                        <p className="left-text">Ngân hàng: Vietcombank</p>
                        <p className="left-text">Số tài khoản: 0451000327817</p>
                        <p className="left-text">Tên tài khoản: Trần Ngọc Hiếu</p>
                        <p className="left-text">Nội dung : {props.data.combo_code}</p>
                        <p className="left-text">Số tiền chuyển khoản: {FormatMoney(totalCK)}</p>
                    </div>
                    <img src={linkIMGQR} data-type='qr' alt="QR Code" style={{ 'width': '107px', 'height': '107px' }} />
                </div>
                </>
                :null}
                <div>
                    {ServiceList.map((e, index) =>
                        <div key={index} className='text-box-pay'>
                            <b className='left-text' >{e.title}</b>
                            {e.subtitle.map((subtitle, index2) =>
                                <p className='left-text' style={{'font-weight':'500'}} key={index2 * 5}>{subtitle}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
       
            
            
        </>
    )
}