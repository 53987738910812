import './TravelDetailSupplier.css';
import Info from './Item/Info';
import Note from './Item/Note';
import { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { use } from 'react';
import { useParams } from 'react-router-dom';
import finish_icon from '../Thongtinchuyendi/Group15.png';
import cancel_icon from '../Thongtinchuyendi/Group15(2).png';
import { useNavigate } from 'react-router-dom';
const TravelDetailSupplier = () => {
    const [data, setData] = useState(null);
    const [locationAllowed, setLocationAllowed] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModal2Visible, setIsModal2Visible] = useState(false);
    const [isModal3Visible, setIsModal3Visible] = useState(false);
    const contractcode = useParams();
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/kiemtradonhang');
    }
    const doNothing = () => { };
    useEffect(() => {
        if (!navigator.geolocation) {
            setLocationAllowed(false);
            return;
        }


        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log("Tọa độ:", position.coords.latitude, position.coords.longitude);
                fetch(`${process.env.REACT_APP_API_SERVER_URL}/contract/updatedriverlocation?contract_code=${contractcode.contractcode}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                    })
                })
                setLocationAllowed(true);

                fetch(`${process.env.REACT_APP_API_SERVER_URL}/contract/getcontractbycontractcode?contract_code=${contractcode.contractcode}`)
                    .then((res) => res.json())
                    .then((data) => {
                        setData(data);
                        
                    })
                    .catch((error) => {
                        console.error("Error:", error.message);
                    });
            },
            (error) => {
                console.error("Lỗi:", error.message);
                setIsModalVisible(true);
                setLocationAllowed(false);
            }
        );
    }, []);
    useEffect(() => {
        if (data && data.data && data.data.data === 4) {
            console.log("Setting modal 2 visible from useEffect");
            setIsModal2Visible(true);
        } else if (data && data.data && data.data.data === 5) {
            console.log("Setting modal 3 visible from useEffect");
            setIsModal3Visible(true);
        }
    }, [data]);
    if (locationAllowed === null) {
        return <p>Đang yêu cầu quyền truy cập vị trí...</p>;
    }
    else if (locationAllowed === false) {

        return (
            <>
                <Modal
                    title={null}
                    open={true}
                    onCancel={() => setIsModalVisible(false)}
                    width={500}
                    closable={false}
                    footer={null}
                    centered={true}
                    className="contact-modal"
                    mask={true}
                    maskStyle={{ backgroundColor: "white", top: '64px' }}
                    style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                    bodyStyle={{ padding: 0 }}
                    wrapClassName="transparent-modal-wrapper"
                >
                    <div className="finish-icon">
                        <img src={cancel_icon} alt="finish-icon" />
                    </div>
                    <h2 className="success">Xin lỗi !</h2>
                    <p className="text-finish">Bạn chưa bật định vị website.</p>
                    <p className="text-finish" style={{ 'text-align': 'center' }}>
                    Vui lòng vào cài đặt cho phép website truy cập vị trí của bạn để hiển thị thông tin cho khách hàng.</p>
                    <Button onClick={() => window.location.reload()} className="confirm-button confirm-button-error">
                        OK
                    </Button>
                </Modal>
            </>
        )
    }

    if (!data) {
        return <p>Loading dữ liệu...</p>;
    }
    const showModal2 = data && data.data && data.data.data === 4;
    const showModal3 = data && data.data && data.data.data === 5;
    console.log("Show modal 2:", showModal2);
    console.log("Show modal 3:", showModal3);
    return (

        <div className='content-container'>
            {showModal2 == false && showModal3 == false && (
            <div className='detail-container'>
                <Info data={data} />
                <Note data={data} />
            </div>
            )}
            <Modal
                title={null}
                open={showModal2}
                onCancel={doNothing}
                width={500}
                closable={false}
                footer={null}
                centered={true}
                className="contact-modal"
                mask={true}
                maskStyle={{ backgroundColor: "white", top: '64px' }}
                style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                bodyStyle={{ padding: 0 }}
                wrapClassName="transparent-modal-wrapper"
            >
                <div className="finish-icon">
                    <img src={finish_icon} alt="finish-icon" />
                </div>
                <h2 className="success">HOÀN THÀNH</h2>
                <div className="booking-finish-content">
                    <p className="text-finish">Chuyến đi đã hoàn thành tốt đẹp.</p>

                </div>
                
            </Modal>
            <Modal
                title={null}
                open={showModal3}
                onCancel={doNothing}
                width={500}
                closable={false}
                footer={null}
                centered={true}
                className="contact-modal"
                mask={true}
                maskStyle={{ backgroundColor: "white", top: '64px' }}
                style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                bodyStyle={{ padding: 0 }}
                wrapClassName="transparent-modal-wrapper"
            >
                <div className="finish-icon">
                    <img src={cancel_icon} alt="cancel-icon" />
                </div>
                <h2 className="success">Xin lỗi !</h2>
                <p className="text-finish">Chuyến đi đã bị huỷ</p>
                
            </Modal>
        </div>

    );
};

export default TravelDetailSupplier;
