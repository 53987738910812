

import './BaoGiaDetail.css';
import PaymentInfo from './Item/PaymentInfo';
import TravelInfo from './Item/TravelInfo';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const BaoGiaDetail = ({data,setOrderCode,type}) => {
    // console.log(data)
    const { orderCode } = useParams();
    const navigate = useNavigate();
    
  useEffect(() => {
    if (!data) {
      setOrderCode(orderCode);
      navigate('/kiemtradonhang');
    }
  });
    if(!data){
        return;
    }
    else 
    return (
        <>
            <div className='content-container'>
                <div className='detail-container'>
                    <TravelInfo data = {data}/>
                    <PaymentInfo data={data}/>
                </div>
            </div>

            
        </>
        
    )
};
export default BaoGiaDetail;