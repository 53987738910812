import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker, Modal } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClockCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"; 
import localeData from "dayjs/plugin/localeData"; 
dayjs.extend(weekday); 

dayjs.extend(localeData); 
const PriceStatus = () => {
  const [form] = Form.useForm();
  const [contactForm] = Form.useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const { priceCode } = useParams();
  const [formValue, setFormValue] = useState(null);
  const location = useLocation();
  const idCode = location.state?.modalId;
  console.log(priceCode)
  
  const fetchPriceStatus = async () => {
      try {
        setLoading(true);
        console.log("Fetching with URL:", 
          `${process.env.REACT_APP_API_SERVER_URL}/orderquotation/getquotationbycode?code=${priceCode}`);
        const res = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/orderquotation/getquotationbycode?code=${priceCode}`,
        );
        if (!res.ok) {
          throw new Error(`API responded with status: ${res.status}`);
        }
        const data = await res.json();
        console.log(data)
        // const formattedPickupTime = data.data[0].pickup_time
        //   ? dayjs(data.data[0].pickup_time).format('DD/MM/YYYY HH:mm')
        //   : "Chưa có";

        // const formattedReturnTime = data.data[0].return_time
        //   ? dayjs(data.data[0].return_time).format('DD/MM/YYYY HH:mm')
        //   : "Chưa có";

        // Set form values with proper date formatting
        form.setFieldsValue({
          member: data.data[0].member,
          pickup_time: data.data[0].pickup_time,
          pickup_address: data.data[0].pickup_address,
          destination: data.data[0].destination,
          return_time: data.data[0].return_time || "Chưa có",
          customer_name: data.data[0].customer_name,
          customer_phone: data.data[0].customer_phone,
          status: data.data[0].status,
          cost: data.data[0].cost || "Chưa có",
          reason: data.data[0].reason || "Không có"
        });
      } catch (error) {
        console.error("Error fetching price status:", error);
        setErrorMessage("Không thể tải thông tin báo giá");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchPriceStatus();
    }, [priceCode,form]);

  

  if (loading) {
    return <div>Loading...</div>; // Add proper loading component here
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>; // Add proper error component here
  }

  return (
    <div className="booking-container">
      <div className="booking-content">
        <h2 className="head-label">
          <b>Thông tin báo giá</b>
        </h2>
        <h3 className="sub-label">
          <b>Mã báo giá: {priceCode}</b>
        </h3>

        <Form form={form} layout="vertical" className="booking-form">
          <h4 className="label">
            {language === "1" ? "" : "Báo giá"}
          </h4>
          <Form.Item
            name="cost"
            className="form-input"
            rules={[{}]}
          >
            <Input className="booking-input" type="text" readOnly />
          </Form.Item>
          <h4 className="label">
            {language === "1" ? "" : "Số người đi và thành phần"}
          </h4>
          <Form.Item
            name="member"
            className="form-input"
            rules={[{ required: true, message: "Cần nhập số người đi và thành phần!" }]}
          >
            <Input className="booking-input" type="text" readOnly />
          </Form.Item>

          <h4 className="label">{language === "1" ? "" : "Ngày giờ đón"}</h4>
          <Form.Item
            name="pickup_time"
            className="form-input"
            rules={[{ required: true, message: "Cần chọn ngày giờ đón" }]}
          >
            
            <Input
              className="booking-input"
              readOnly
            />
          </Form.Item>

          <h4 className="label">
            {language === "1" ? "" : "Các điểm đón khách"}
          </h4>
          <Form.Item
            name="pickup_address"
            className="form-input"
            rules={[{ required: true, message: "Cần nhập điểm đón" }]}
          >
            <Input
              className="booking-input"
              type="text"
              placeholder="91 Trần Đại Nghĩa, 40 Tạ Quang Bửu"
            />
          </Form.Item>

          <h4 className="label">
            {language === "1" ? "" : "Các điểm đến"}
          </h4>
          <Form.Item
            name="destination"
            className="form-input"
            rules={[{ required: true, message: "Cần nhập điểm đến" }]}
          >
            <Input
              className="booking-input"
              type="text"
              placeholder="Thành phố Hải Phòng"
            />
          </Form.Item>

          <h4 className="label">{language === "1" ? "" : "Thông tin thêm"}</h4>
          <Form.Item name="return_time" className="form-input">
          <Input
              className="booking-input"
              readOnly
            />
          </Form.Item>

          {form.getFieldValue("status") === 3 ? (
            <>
              <h4 className="label">
                {language === "1" ? "" : "Trạng thái : Huỷ"}
              </h4>
              <Form.Item
                name="reason"
                className="form-input"
                rules={[{ required: true, message: "Cần nhập điểm đến" }]}
              >
                <Input
                  className="booking-input"
                  type="text"
                />
              </Form.Item>
            </>
          ) :
            (
              <h4 className="label">
                {language === "1" ? "" : "Trạng thái"} : {(() => {
                  switch (form.getFieldValue("status")) {
                    case 0:
                      return "Mới";
                    case 1:
                      return "Đang báo giá";
                    case 2:
                      return "Đã chốt";
                    case 3:
                      return "Đã huỷ";
                    default:
                      return "";
                  }
                })()}
              </h4>
            )}
          <Form.Item className="form-input">
            <Button className="form-submit-button heading-4 booking-button" htmlType="submit" onClick={() => navigate("/datxe")}>
              {language === "1" ? "Booking now" : "Quay lại trang liên hệ"}
            </Button>
          </Form.Item>

        </Form>
      </div>
    </div>
  );
};

export default PriceStatus;