import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import ListService from "./Component/ListService";
import "./BookingPage.css";
import { createQuotation } from "../../services/quotation";
import "./Component/Loading.css";
import { HashIcon } from "lucide-react";

const BookingPage = (props) => {
  const [form] = Form.useForm();
  const [contactForm] = Form.useForm();
  const navigate = useNavigate();
  const [modalCode, setModalCode] = useState("");
  const [modalId, setModalId] = useState("");
  const language = localStorage.getItem("language");
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [hasIcon, setHasIcon] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const showFinishModal = () => {
    setIsLoadingVisible(false);
    setIsModal2Visible(true);
  };

  const handleFinishModal = () => {
    setIsModal2Visible(false);
    navigate(props.link);
  };

  const showContactModal = (values) => {
    setFormValue(values);
    setIsModelVisible(true);
  };

  const handleCancel = () => {
    setIsModelVisible(false);
    contactForm.resetFields();
  };

  const handleSubmitContact = async () => {
    try {
      const values = await contactForm.validateFields();
      const completeFormData = {
        ...formValue,
        ...values
      };

      console.log(completeFormData);
      setIsModelVisible(false);
      setIsLoadingVisible(true);

      createQuotation(completeFormData, (res) => {
        console.log(res.data.data);
        setModalCode(res.data.data.code);
        setModalId(res.data.data._id);
        if (res.data.data) {
          showFinishModal();
        }
      });

      setIsModelVisible(false);
      contactForm.resetFields();
      form.resetFields();
    } catch (err) {
      setIsLoadingVisible(false);
      console.log(err);
    }
  };

  const onFinish = async (values) => {
    showContactModal(values);
  };

  const handleClickNew = (e) => {
    e.preventDefault();
    navigate(`/baogia/${modalCode}`, { state: { modalId } });
  };
  useEffect(() => {
    const initalValue = {};
    if (props.pickup_address) {
      initalValue.pickup_address = props.pickup_address;
    }
    if (props.pickup_destination) {
      initalValue.destination = props.pickup_destination;
    }
    
    form.setFieldsValue(initalValue);
  }, []);


  return (
    <div className="booking-container">
      <div className="booking-content">
        <ListService activeService={props.activeService} />
        <h2 className="head-label">
          <b>{props.serviceName}</b>
        </h2>

        <Form
          form={form}
          layout="vertical"
          className="booking-form"
          onFinish={onFinish}

        >
          {/* Số người đi */}
          <h4 className="label">
            {language === "1" ? "" : "Số người đi và thành phần"}
          </h4>
          <Form.Item
            name="member"
            className="form-input"
            rules={[{ required: true, message: "Cần nhập số người đi và thành phần!" }]}
          >
            <Input className="booking-input" type="text" placeholder="3 người lớn, 2 trẻ nhỏ" />
          </Form.Item>

          {/* Ngày giờ đón */}
          <h4 className="label">{language === "1" ? "" : "Ngày giờ đón"}</h4>
          <Form.Item
            name="pickup_time"
            className="form-input"
            rules={[{ required: true, message: "Cần chọn ngày giờ đón" }]}
          >
            <Input
              className="booking-input full-width-datepicker"
              placeholder="12:00 - 20/02/2025"
              type="text"
            />
          </Form.Item>

          {/* Điểm đón khách */}
          <h4 className="label">{language === "1" ? "" : "Các điểm đón"}</h4>
          <Form.Item name="pickup_address" className="form-input" rules={[{ required: true, message: "Cần nhập điểm đón" }]}>
            <Input className="booking-input" type="text" placeholder="91 Trần Đại Nghĩa, 40 Tạ Quang Bửu" 
            style={{ fontFamily: "Quicksand", fontWeight:'700' }} 

            suffix={
              (hasIcon && props.pickup_address) && (
                <span className="icon" onClick={() => {setHasIcon(false); form.setFieldsValue({ pickup_address: "" })}}>
                  <img src="Image/BookingPage/Icon/clear_24px.png" alt="icon" />
                </span>
              )
            } 
            />
          </Form.Item>
          
          {/* Điểm đến */}
          <h4 className="label">{language === "1" ? "" : "Các điểm đến"}</h4>
          <Form.Item
            name="destination"
            className="form-input"
            rules={[{ required: true, message: "Cần nhập điểm đến" }]}
          >

            <Input
              className="booking-input"
              type="text"
              placeholder="Thành phố Hải Phòng"
              style={{ fontFamily: "Quicksand", fontWeight:'700' }} 

              suffix={
              (hasIcon && props.pickup_destination) && (
                <span className="icon" onClick={() => {setHasIcon(false); form.setFieldsValue({ destination: "" })}}>
                  <img src="Image/BookingPage/Icon/clear_24px.png" alt="icon" />
                </span>
              )
            } 
            />


          </Form.Item>

          {/* Các phần còn lại giữ nguyên */}
          {props.serviceName === "Đón tại sân bay" && (
            <>
              <h4 className="label">{language === "1" ? "" : "Số hiệu chuyến bay"}</h4>
              <Form.Item
                name="return_time"
                className="form-input"
              >
                <Input
                  className="booking-input full-width-datepicker"
                  placeholder="VN250"
                  type="text"
                />
              </Form.Item>
            </>
          )}
          {props.serviceName === "Đặt xe du lịch" && (
            <>
              <h4 className="label">{language === "1" ? "" : "Link tour tham khảo(nếu có)"}</h4>
              <Form.Item
                name="return_time"
                className="form-input"
              >
                <Input
                  className="booking-input full-width-datepicker"
                  placeholder="hanoisuntravel.com/hung-yen/tour-hung-yen-1-ngay"
                  type="text"
                />
              </Form.Item>
            </>
          )}
          {(props.serviceName === "Đưa đón villa" || props.serviceName === "Xe đi tỉnh" || props.serviceName === "Liên hệ báo giá") && (
            <>
              <h4 className="label">{language === "1" ? "" : "Ngày giờ về (nếu có)"}</h4>
              <Form.Item
                name="return_time"
                className="form-input"
              >
                <Input
                  className="booking-input full-width-datepicker"
                  placeholder="12:00 - 25/02/2025"
                  type="text"
                />
              </Form.Item>
            </>
          )}

          <Form.Item className="form-input">
            <Button className="form-submit-button heading-4 booking-button" htmlType="submit">
              {language === "1" ? "Booking now" : "LIÊN HỆ BÁO GIÁ"}
            </Button>
          </Form.Item>
        </Form>

        {/* Các Modal giữ nguyên */}
        <Modal
          title={null}
          open={isModelVisible}
          onCancel={handleCancel}
          width={500}
          closable={false}
          footer={null}
          centered={true}
          className="contact-modal"
          mask={true}
          maskStyle={{ backgroundColor: "white", top: '64px' }}
          style={{ backgroundColor: 'transparent', marginTop: `64px` }}
          bodyStyle={{ padding: 0 }}
          wrapClassName="transparent-modal-wrapper"
          getContainer={() => document.querySelector('.booking-content')}
        >
          <div className="modal-header">
            <h2 className="modal-title">Thông tin liên hệ</h2>
            <CloseOutlined className="modal-close-icon" onClick={handleCancel} />
          </div>

          <Form form={contactForm} layout="vertical" className="contact-form">
            <Form.Item
              name="customer_name"
              label="Tên khách hàng"
              className="form-input-modal"
              rules={[{ required: true, message: "Vui lòng nhập họ tên!" }]}
            >
              <Input placeholder="Tên khách hàng" className="contact-input" />
            </Form.Item>

            <Form.Item
              name="customer_phone"
              className="form-input-modal"
              label="Số điện thoại liên hệ (ưu tiên Zalo, iMessage)"
              rules={[
                { required: true, message: "Vui lòng nhập số điện thoại!" },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Số điện thoại phải có đúng 10 chữ số!",
                },
              ]}
            >
              <Input placeholder="Số điện thoại liên hệ" inputMode="numeric" className="contact-input" maxLength={10} />
            </Form.Item>

            <Form.Item className="form-button-wrapper">
              <Button type="primary" onClick={handleSubmitContact} className="confirm-button">
                XÁC NHẬN
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={null}
          open={isLoadingVisible}
          onCancel={() => setIsLoadingVisible(false)}
          width={500}
          closable={false}
          footer={null}
          centered={true}
          className="loading-modal"
          mask={true}
          maskStyle={{ backgroundColor: "white", top: "64px" }}
          style={{ backgroundColor: "transparent", marginTop: "64px" }}
          bodyStyle={{ padding: 0, border: "none" }}
          wrapClassName="transparent-modal-wrapper"
          getContainer={() => document.querySelector(".booking-content")}
          modalRender={(node) => (
            <div style={{ border: "none" }}>
              {React.cloneElement(node, {
                style: { ...node.style, border: "none" }
              })}
            </div>
          )}
        >
          <div className="loading-container" style={{ textAlign: "center" }}>
            <Spin indicator={antIcon} />
            <h3 style={{ marginTop: 20 }}>Đang xử lý...</h3>
            <p>Vui lòng đợi trong giây lát</p>
          </div>
        </Modal>

        <Modal
          title={null}
          open={isModal2Visible}
          onCancel={handleFinishModal}
          width={500}
          closable={false}
          footer={null}
          centered={true}
          className="contact-modal"
          mask={true}
          maskStyle={{ backgroundColor: "white", top: '64px' }}
          style={{ backgroundColor: 'transparent', marginTop: `64px` }}
          bodyStyle={{ padding: 0 }}
          wrapClassName="transparent-modal-wrapper"
          getContainer={() => document.querySelector('.booking-content')}
        >
          <div className="finish-icon">
            <img src="Image/BookingPage/Icon/Group 15 (1).png" alt="finish-icon" />
          </div>
          <h2 className="success"> Thành công</h2>
          <div className="booking-finish-content">
            <p className="text-finish">Pips đã nhận được yêu cầu báo giá của bạn, chúng tôi sẽ liên hệ với bạn sớm nhất trong giờ hành chính.</p>
            <p className="text-finish">Link báo giá: </p>
            <p className="text-finish">
              <a href={`${process.env.REACT_APP_WEB_SERVER_URL}/baogia/${modalCode}`} onClick={handleClickNew} rel="noopener noreferrer">
                https://pippip.vn/baogia/{modalCode}
              </a>
            </p>
          </div>
          <Button onClick={handleFinishModal} className="confirm-button-booking confirm-button-finish-booking">
            OK
          </Button>
        </Modal>
      </div>
    </div>
  );
};

export default BookingPage;