import axios from "axios";

export const AxiosConfig = () => {
    console.log(process.env.REACT_APP_API_SERVER_URL);  
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER_URL}`,
    headers: {
        "Content-Type": "application/json"
    },
  });

  return instance;
};
