import {AxiosConfig} from "../config/Axios_config";
export function createQuotation(data, callback) {
    const axios = AxiosConfig();
  
    axios
      .post(`/orderquotation/create`, data)
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        if (err.response) {
            callback(err.response.data);
        }
      });
  }