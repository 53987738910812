import Hanhtrinh from './Item/Hanhtrinh';
import Thongtinxe from './Item/Thongtinxe';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'antd';
import finish_icon from './Group15.png';
import cancel_icon from './Group15(2).png';
import { useNavigate } from 'react-router-dom';
const Thongtinchuyendi = () => {
    const [data, setData] = useState(null);
    const {contractId} = useParams();
    const [isModal2Visible, setIsModal2Visible] = useState(false);
    const [isModal3Visible, setIsModal3Visible] = useState(false);
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/kiemtradonhang');
    }
    const doNothing = () => {};
    useEffect(() => {
        async function fetchOrder() {
            if (!contractId) return;
            try {
                const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/contract/getcontractbycontractid?contract_id=${contractId}`);
                if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
                const responseData = await res.json();
                setData(responseData);
                if (responseData.data.contract_status === 4) {
                    setIsModal2Visible(true);
                }
                else if (responseData.data.contract_status === 5) {
                    setIsModal3Visible(true);
                }
            } catch (error) {
                console.error("Error fetching order:", error);
            }
        }
        
        fetchOrder();
    }, [contractId]);
    
    if (!data) {
        return <p>Loading...</p>; 
    }

    return (
        <div className='content-container'>
            <div className='detail-container'>
                <Thongtinxe data={data} contractId={contractId} />
                <Hanhtrinh data={data} />
            </div>
            <Modal
                title={null}
                open={isModal2Visible}
                onCancel={doNothing}
                width={500}
                closable={false}
                footer={null}
                centered={true}
                className="contact-modal"
                mask={true}
                maskStyle={{ backgroundColor: "white", top: '64px' }}
                style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                bodyStyle={{ padding: 0 }}
                wrapClassName="transparent-modal-wrapper"
                getContainer={() => document.querySelector('.content-container')}
            >
                <div className="finish-icon">
                    <img src={finish_icon} alt="finish-icon" />
                </div>
                <h2 className="success"> Hoàn thành</h2>
                <div className="booking-finish-content">
                    <p className="text-finish">Chuyến đi đã hoàn thành tốt đẹp.
                    Bạn có thể xem lại thông tin bằng tính năng kiểm tra đơn hàng hoặc đăng nhập ứng dụng Pippip.</p>
                    
                </div>
                <Button onClick={handleNavigate} className="confirm-button-booking confirm-button-finish-booking">
                    OK
                </Button>
            </Modal>
            <Modal
                title={null}
                open={isModal3Visible}
                onCancel={doNothing}
                width={500}
                closable={false}
                footer={null}
                centered={true}
                className="contact-modal"
                mask={true}
                maskStyle={{ backgroundColor: "white", top: '64px' }}
                style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                bodyStyle={{ padding: 0 }}
                wrapClassName="transparent-modal-wrapper"
                getContainer={() => document.querySelector('.content-container')}
            >
                <div className="finish-icon">
                    <img src={cancel_icon} alt="cancel-icon" />
                </div>
                <h2 className="success">Xin lỗi !</h2>
                <p className="text-finish">Chuyến đi đã bị huỷ
                Bạn có thể xem lại thông tin bằng tính năng kiểm tra đơn hàng hoặc đăng nhập ứng dụng Pippip.</p>
                <Button onClick={handleNavigate} className="confirm-button confirm-button-error">
                    OK
                </Button>
            </Modal>
        </div>
    );
};

export default Thongtinchuyendi;