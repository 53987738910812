import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

export default function TravelDetail(props) {
    const [hasSupplier, setHasSupplier] = useState(false);
    const data = props.data || {};
    
    const isExpanded = props.isExpanded;
    
    const toggleAccordion = () => {
        props.onToggle();
    };
    
    useEffect(() => {
        if (data.supplier) {
            setHasSupplier(true);
        }
    }, [data]);
    
    const getContactStatus = (status) => {
        const statusMap = {
            0: "Mới tạo",
            1: "Đi đón khách",
            2: "Đang đi",
            3: "Đã trả khách",
            4: "Đã hoàn thành",
            5: "Đã hủy",
            6: "Đã thanh toán"
        };
        return statusMap[status] || "";
    };
    const driverPlace = () => {
        if (data.supplier.driver_lat !=0 && data.supplier.driver_long !=0) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${data.supplier.driver_lat},${data.supplier.driver_long}`);
        }
        else{
            alert("Không có dữ liệu vị trí xe");
        }
    }
    return (
        <div className="travel-detail">
            <button
                onClick={toggleAccordion}
                className="button-expand-travel-info"
            >
                <span className='left-label'>
                    {`Chuyến ${data.service_name || ''}: ${getContactStatus(data.contract_status)}`}
                </span>
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            
            {isExpanded  && (
                <div>
                    {hasSupplier && (
                        <div>
                            <p className='left-label'>Thông tin xe</p>
                            <div className='text-box-pay2'>
                                <div className="text-content-pay">
                                    <p className='left-text'>Lái xe: {data.supplier.driver_name}-{data.supplier.driver_phone}</p>
                                    <p className='left-text'>Loại xe: {data.supplier.car_name}</p>
                                    <p className='left-text'>Biển số xe: {data.supplier.car_number}</p>
                                </div>
                                <div className="icon-detail-special" onClick={() => window.location.href = `tel:${data.supplier.driver_phone}`} style={{'cursor':'pointer'}} >
                                <img src='/Image/BookingPage/Icon/call_24px.png' data-type='tel' alt="tel"  />
                                <p style={{'font-weight':'500'}}>Gọi điện</p>
                                </div>
                                <div  onClick={driverPlace} className="icon-detail-special" style={{'cursor':'pointer'}} >
                                <img src='/Image/BookingPage/Icon/place_24px_outlined.png' data-type='tel' alt="place"  />
                                <p style={{'font-weight':'500'}}>Vị trí xe</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <p className='left-label'>Thời gian đón</p>
                    <div className='text-box-pay'>
                        <p className='left-text'>{data.pickup_time}</p>
                    </div>
                    <p className='left-label'>Điểm đón</p>
                    {data.contract_destination.map((e, idx) =>
                        e.type === 1 ? <div key={`pickup-${idx}`} className='text-box-pay'><p className='left-text'>{e.location}</p></div> : null
                    )}
                    <p className='left-label'>Điểm đến</p>
                    {data.contract_destination.map((e, idx) =>
                        e.type === 2 ? <div key={`destination-${idx}`} className='text-box-pay'><p className='left-text'>{e.location}</p></div> : null
                    )}
                </div>
            )}
        </div>
    );
}