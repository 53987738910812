import React, { useState, useEffect } from 'react';
import TravelDetail from './TravelDetail';

export default function TravelInfo(props) {
    const [expandedIndex, setExpandedIndex] = useState(-1); 
    
    const handleToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? -1 : index);
    };

    useEffect(() => {
        if (props.data?.contract_list?.length > 0) {
            const firstUnfinishedIndex = props.data.contract_list.findIndex(
                contract => contract.contract_status < 4
            );
            if (firstUnfinishedIndex !== -1) {
                setExpandedIndex(firstUnfinishedIndex);
            }
        }
    }, [props.data?.contract_list]);

    return (
        <>
            <div className="travel-info">
                <h1 className="head-label" style={{ marginTop: '68px' }}>Thông tin chuyến đi</h1>
                <p className="label-payment">Mã đơn hàng: {props.data.combo_code}</p>
                
                {props.data?.contract_list?.map((e, index) => (
                    <div key={index}>
                        <TravelDetail 
                            data={e} 
                            isExpanded={expandedIndex === index}
                            onToggle={() => handleToggle(index)}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}