import './Layout1.css';
import Header from '../Component/Header/Header';
import Copyright from '../Component/Copyright/Copyright';

export default function BookingLayout(props) {
  return(
    <div className="layout-container">
    <div className="header-container">
      <Header />
    </div>
    <div className="content-container">
      {props.children}
    </div>
    
  </div>
  )
}