import React from 'react';
import './ServiceItem.css';
import { useNavigate } from 'react-router-dom';

const ServiceItem = ({ icon, label, active = false,link,onClick}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    onClick(); 
    navigate(link);
    window.location.reload(); 
  };
  return (
    <>
    <div 
      className={`service-item ${active ? 'active' : ''}`}
      onClick={handleClick}
      
    >
      <div className={`service-icon ${active ? 'active' : ''}`}>
        <img 
          src={`/Image/BookingPage/Icon/${icon}`} 
          alt={label} 
        />
      </div>
      <div className="service-text">
        <div className={`service-label ${active ? 'active' : ''}`}>{label}</div>
      </div>
    </div>
    
    </>
  );
};

export default ServiceItem;