export default function Note(props) {
    return(
        <>
        <div className="travel-info">
            <h1 className="head-label" style={{ marginTop: '68px' }}>Ghi chú và thanh toán</h1>
            <p className='left-label'>Ghi chú</p>
            <div className='text-box-pay note-box'>
                <p className='left-text' style={{'font-size':'14px', whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>{props.data.data.driver_note}</p>
            </div>
            <p className="left-label">Thanh toán</p>
            <div className="text-box-pay note-box">
                <p className="left-text" style={{'font-size':'14px'}}>{props.data.data.driver_payment}</p>
            </div>
            <p className="left-label">Hotline hỗ trợ lái xe</p>
            <div className='text-box-pay2 note-box'>
                    <p className='left-text' style={{'font-size':'14px'}}>{props.data.data.hotline_name} - {props.data.data.hotline_phone}</p>
                    <div className="icon-detail-special" onClick={() => window.location.href = `tel:${props.data.data.hotline_phone}`} style={{ 'cursor': 'pointer','padding-bottom':'8px' }} >
                        <img src='/Image/BookingPage/Icon/call_24px.png' data-type='tel' alt="tel" />
                    </div>
            </div>
        </div>
        </>
    )
}