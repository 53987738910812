import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";

export default function HomeLayout(props) {
  return(
    <>
      <Header />
      {props.children}
      {/* <MobileLink /> */}
      <Footer />
    </>
  )
}