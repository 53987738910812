import React, { useState } from 'react';
import ServiceItem from './ServiceItem';
import './ListService.css';

const ListService = (props) => {
  const [activeService, setActiveService] = useState('lien-he');
  
  const services = [
    { id: 'lien-he', icon: 'money_inactive.png', label: 'Liên hệ Báo giá',link:"/datxe" },
    { id: 'di-len', icon: 'local_airport_24px.png', label: 'Đi lên Sân bay',link:"/dilensanbay" },
    { id: 'don-tai', icon: 'flight_land_24px_outlined.png', label: 'Đón tại Sân bay',link:"/dontaisanbay" },
    { id: 'xe-di', icon: 'domain_24px_outlined.png', label: 'Xe đi Tỉnh',link:"/xeditinh" },
    { id: 'dua-don', icon: 'home_24px_outlined.png', label: 'Đưa đón Villa',link:"/duadonvilla" },
    { id: 'dat-xe', icon: 'card_travel_24px_outlined.png', label: 'Đặt xe Du lịch',link:"/datxedulich" },
  ];
  const handleSelectService = (serviceId) => {
    setActiveService(serviceId);
  };
  
  return (
    <div className="service-list">
      {services.map(service => (
        <ServiceItem
          key={service.id}
          icon={service.icon}
          label={service.label}
          active={service.id === props.activeService}
          link={service.link}
          onClick={() => setActiveService(service.id)}
        />
      ))}
    </div>
  );
};

export default ListService;