import React from 'react';
import './Thongtinxe.css';
import { useState } from 'react';
import { Form, Modal, Input, Button, Radio } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { addFeedback, feedback } from '../../../services/feedback';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { use } from 'react';
const { TextArea } = Input;
export default function Thongtinxe(props) {
    const [responseform] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [isModal2Visible, setIsModal2Visible] = useState(false);
    // const [isModal3Visible, setIsModal3Visible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [feedback, setFeedback] = useState("");
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const driverPlace = () => {
        if (props.data.data.driver.lat != 0 && props.data.data.driver.lat != null && props.data.data.driver.long != 0 && props.data.data.driver.long != null) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${props.data.data.driver.lat},${props.data.data.driver.long}`);
        }
        else {
            alert("Không có dữ liệu vị trí xe");
        }
    }
    // useEffect(() => {
    //     if (props.data.data.contract_status === 4) {
    //         setIsModal2Visible(true);
    //     }
    //     else if (props.data.data.contract_status === 5) {
    //         setIsModal3Visible(true);
    //     }
    //     else {
    //         setIsModal2Visible(false);
    //         setIsModal3Visible(false);
    //     }
    // }, []);
    useEffect(() => {
        if (props.data.data.feedback.context != null && props.data.data.feedback.context != "") {
            if (props.data.data.feedback.context.includes("Chuyến đi ổn")) {
                setSelectedValue("CÓ");
                const updatedContext = props.data.data.feedback.context;
                setFeedback(updatedContext);
                setInputValue(updatedContext);
            } else {
                setSelectedValue("KHÔNG");
                const updatedContext = props.data.data.feedback.context;
                setFeedback(updatedContext);
                setInputValue(updatedContext);
            }
        }
    }, [props.data.data.feedback.context]);
    const showResponse = () => {
        setIsModalVisible(true);
    }
    const handleRatingChange = (e) => {
        setSelectedValue(e.target.value);
        setInputValue('');
        responseform.setFieldsValue({ feedback: '' });
    };
    const sendFeedback = async () => {
        let feedbackstring = responseform.getFieldsValue().feedback != undefined ? responseform.getFieldsValue().feedback : feedback;
        console.log(feedbackstring);
        console.log(responseform.getFieldsValue());
        if (responseform.getFieldsValue().rating === "CÓ") {
            feedbackstring = "Chuyến đi ổn. " + feedbackstring;
        }
        else if (responseform.getFieldsValue().rating === "KHÔNG") {
            feedbackstring = "Chuyến đi không ổn. " + feedbackstring;
        }
        const completeFeedback = {
            contract_id: props.contractId,
            context: feedbackstring,
            customer_id: props.data.data.customer_id,
        };
        addFeedback(completeFeedback, (res) => {
            window.location.reload();
        })


        responseform.resetFields();


    }
    const linkavatar = `${process.env.REACT_APP_BACKEND_SERVER_URL}/${props.data.data.driver.avatar}`;
    return (
        <>
            <div className="travel-info">
                <h1 className="head-label" style={{ marginTop: '68px' }}>Thông tin xe</h1>
                <p className='left-label'>Thông tin xe</p>

                <div className='text-box-pay2 box-response'>
                    <div className='responsive-avatar-text'>
                    <img className="avatar" src={linkavatar} alt='avatar' />
                    <div className="text-content-pay box-responsive-content">
                        <p className='left-text box-responsive-text'>{props.data.data.driver.name}-{props.data.data.driver.phone}</p>
                        <p className='left-text box-responsive-text'>{props.data.data.driver.car_name}</p>
                        <p className='left-text box-responsive-text'>{props.data.data.driver.car_number}</p>
                    </div>
                    </div>
                    <div className='responsive-icon'>
                    <div className="icon-detail-special" onClick={() => window.location.href = `tel:${props.data.data.driver.phone}`} style={{ 'cursor': 'pointer' }} >
                        <img src='/Image/BookingPage/Icon/call_24px.png' data-type='tel' alt="tel" />
                        <p style={{ 'font-weight': '500' }}>Gọi điện</p>
                    </div>
                    <div onClick={driverPlace} className="icon-detail-special" style={{ 'cursor': 'pointer' }} >
                        <img src='/Image/BookingPage/Icon/place_24px_outlined.png' data-type='tel' alt="place" />
                        <p style={{ 'font-weight': '500' }}>Vị trí xe</p>
                    </div>
                    </div>
                </div>
                {feedback != null && feedback != "" ?
                    <div>
                        <p className='left-label'>Phản hồi về chuyến đi</p>
                        <div className='text-box-pay'>
                            <p className='left-text'>{feedback}</p>
                        </div>
                    </div>
                    : null}
                <p className='left-label'>Hotline hỗ trợ</p>
                <div className='text-box-pay2'>
                    <p className='left-text'>{props.data.data.hotline.hotline_name} - {props.data.data.hotline.hotline_phone}</p>
                    <div className="icon-detail-special" onClick={() => window.location.href = `tel:${props.data.data.hotline.hotline_phone}`} style={{ 'cursor': 'pointer', 'padding-bottom': '8px' }} >
                        <img src='/Image/BookingPage/Icon/call_24px.png' data-type='tel' alt="tel" />
                    </div>
                </div>
                <p className='left-label'>Ghi chú</p>
                <div className='text-box-pay'>
                    <p className='left-text'>{props.data.data.note != "" ? props.data.data.note : "Không có"}</p>
                </div>
                <button className='btn-response' onClick={showResponse} >Gửi phản hồi</button>
                <Modal
                    title={null}
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    width={500}
                    closable={false}
                    footer={null}
                    centered={true}
                    className="contact-modal"
                    mask={true}
                    maskStyle={{ backgroundColor: "white", top: '64px' }}
                    style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                    bodyStyle={{ padding: 0 }}
                    wrapClassName="transparent-modal-wrapper"
                    getContainer={() => document.querySelector('.content-container')}
                >
                    <div className="modal-header">
                        <h2 className="modal-title">Gửi phản hồi</h2>
                        <CloseOutlined className="modal-close-icon" onClick={() => setIsModalVisible(false)} />
                    </div>

                    <Form form={responseform} layout="vertical" className="contact-form" >
                        <p className='left-label'>Chuyến hôm nay bạn đi có ổn không?</p>
                        <Form.Item name="rating" className="form-input">
                            <Radio.Group value={selectedValue} onChange={(e) => { setSelectedValue(e.target.value); handleRatingChange(e); }}>
                                <div className="rating-buttons">
                                    <Radio.Button value="CÓ" className={`rating-button-custom ${selectedValue === "CÓ" ? "selected" : ""}`}>
                                        CÓ
                                    </Radio.Button>
                                    <Radio.Button value="KHÔNG" className={`rating-button-custom ${selectedValue === "KHÔNG" ? "selected" : ""}`}>
                                        KHÔNG
                                    </Radio.Button>
                                </div>
                            </Radio.Group>
                        </Form.Item>
                        <p className='left-label'>Viết thêm đánh giá (nếu có)</p>
                        <Form.Item
                            name="feedback"
                            className='form-input'
                        >
                            <TextArea placeholder="Tài xế vui vẻ, nhiệt tình"
                                className="contact-input"
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                defaultValue={inputValue}
                                onChange={handleInputChange} />
                        </Form.Item>



                        <Form.Item className="form-button-wrapper">
                            <Button type="primary" onClick={sendFeedback} className="confirm-button">
                                Gửi phản hồi
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                {/* <Modal
                    title={null}
                    open={isModal2Visible}
                    onCancel={()=>setIsModal2Visible(false)}
                    width={500}
                    closable={false}
                    footer={null}
                    centered={true}
                    className="contact-modal"
                    mask={true}
                    maskStyle={{ backgroundColor: "white", top: '64px' }}
                    style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                    bodyStyle={{ padding: 0 }}
                    wrapClassName="transparent-modal-wrapper"
                    getContainer={() => document.querySelector('.content-container')}
                >
                    <div className="finish-icon">
                        <img src="Image/BookingPage/Icon/Group 15 (1).png" alt="finish-icon" />
                    </div>
                    <h2 className="success"> Hoàn thành</h2>
                    <div className="booking-finish-content">
                        <p className="text-finish">Chuyến đi đã hoàn thành tốt đẹp.
                        Bạn có thể xem lại thông tin bằng tính năng kiểm tra đơn hàng hoặc đăng nhập ứng dụng Pippip.</p>
                        
                    </div>
                    <Button onClick={()=>setIsModal2Visible(false)} className="confirm-button-booking confirm-button-finish-booking">
                        OK
                    </Button>
                </Modal>
                <Modal
                    title={null}
                    open={isModal3Visible}
                    onCancel={()=>setIsModal3Visible(false)}
                    width={500}
                    closable={false}
                    footer={null}
                    centered={true}
                    className="contact-modal"
                    mask={true}
                    maskStyle={{ backgroundColor: "white", top: '64px' }}
                    style={{ backgroundColor: 'transparent', marginTop: `64px` }}
                    bodyStyle={{ padding: 0 }}
                    wrapClassName="transparent-modal-wrapper"
                    getContainer={() => document.querySelector('.content-container')}
                >
                    <div className="finish-icon">
                        <img src="Image/BookingPage/Icon/Group 15 (2).png" alt="finish-icon" />
                    </div>
                    <h2 className="success">Xin lỗi !</h2>
                    <p className="text-finish">Chuyến đi đã bị huỷ
                    Bạn có thể xem lại thông tin bằng tính năng kiểm tra đơn hàng hoặc đăng nhập ứng dụng Pippip.</p>
                    <Button onClick={()=>setIsModal3Visible(false)} className="confirm-button confirm-button-error">
                        OK
                    </Button>
                </Modal> */}
            </div>
        </>
    )
}