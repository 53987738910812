// import 'antd/dist/reset.css';
import 'antd/dist/antd.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useState } from 'react';

import './App.css';
// import Header from './Component/Header/Header';
import Redirect from './Pages/Redirect';
import Home from './Pages/Home/Home';
import CheckOrder from './Pages/CheckOrder/CheckOrder';
import OrderDetail from './Pages/OrderDetail/OrderDetail';
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount';
import HomeLayout from './Layout/HomeLayout';
// import Footer from './Component/Footer/Footer';
import CheckLayout from './Layout/CheckLayout';
import BookingLayout from './Layout/BookingLayout';
import DownloadApp from './Pages/DownloadApp';
import DeleteLayout from './Layout/DeleteLayout';
import BookingPage from './Pages/BookingPage/BookingPage';
import PriceStatus from './Pages/PriceStatus/PriceStatus';
import CheckBaoGia from './Pages/CheckBaoGia/CheckBaoGia';
import BaoGiaDetail from './Pages/BaoGiaDetail/BaoGiaDetail';
import TravelDetail from './Pages/BaoGiaDetail/Item/TravelDetail';
import TravelDetailCustomer from './Pages/TravelDetailCustomer/TravelDetailCustomer';
import Thongtinchuyendi from './Pages/Thongtinchuyendi/Thongtinchuyendi';
import TravelDetailSupplier from './Pages/TravelDetailSupplier/TravelDetailSupplier';
function App() {
  const [ orderDetail, setOrderDetail ] = useState();
  const [ priceCode, setPriceCode ] = useState();
  const [bookingData, setBookingData] = useState();
  const [ orderCode, setOrderCode ] = useState('');
  // type = true is combo contract, type = false is order airport
  const [ type, setType ] = useState();

  return (
    <div id='App' className="App">
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route
            path='*'
            element={<Redirect />}
          />
          <Route 
            path='/checkdonhang' 
            element={<CheckLayout>
              <CheckOrder
                setOrderDetail={setOrderDetail}
                orderCode={orderCode}
                setType={setType}
              />
            </CheckLayout>}
          />
          <Route 
            path='/checkdonhang/:orderCode'
            element={<CheckLayout>
              <OrderDetail
                data={orderDetail}
                setOrderCode={setOrderCode}
                type={type}
              />
            </CheckLayout>}
          />
          {/*todo: add delete(block) cutomer account */}
          <Route 
            path='/xoataikhoan' 
            element={<DeleteLayout>
              <DeleteAccount/>
            </DeleteLayout>}
          />
          <Route 
            path='/'
            element={<HomeLayout>
              <Home />
            </HomeLayout>}
          />
          <Route 
            path='/appdownload'
            element={<DownloadApp />}
          />
          <Route
              path='/datxe'
              element={<BookingLayout>
                <BookingPage link='/datxe' activeService='lien-he' serviceName='Liên hệ báo giá'/>
              </BookingLayout>
              }
            />
          <Route
              path='/dilensanbay'
              element={<BookingLayout>
                <BookingPage link='/dilensanbay' activeService='di-len' serviceName='Đi lên sân bay' pickup_destination='Sân bay nội bài, sân nội địa' />
              </BookingLayout>
              }
            />
          <Route
              path='/dontaisanbay'
              element={<BookingLayout>
                <BookingPage link='/dontaisanbay' activeService='don-tai' serviceName='Đón tại sân bay' pickup_address='Sân bay nội bài, sân nội địa' />
              </BookingLayout>
              }
            />
          <Route
              path='/xeditinh'
              element={<BookingLayout>
                <BookingPage link='/xeditinh' activeService='xe-di' serviceName='Xe đi tỉnh' />
              </BookingLayout>
              }
            />
          <Route
              path='/duadonvilla'
              element={<BookingLayout>
                <BookingPage link='/duadonvilla' activeService='dua-don' serviceName='Đưa đón villa' pickup_destination='Mura Retreat, Xóm Quýt, Ba Vì, Hà Nội'  />
              </BookingLayout>
              }
            />
          <Route
              path='/datxedulich'
              element={<BookingLayout>
                <BookingPage link='/datxedulich' activeService='dat-xe' serviceName='Đặt xe du lịch' pickup_destination='Chùa Nôm , Đền Mẫu, Chùa Chuông. Hưng Yên'  />
              </BookingLayout>
              }
            />
          <Route
            path='/baogia/:priceCode'
            element={<BookingLayout>
              <PriceStatus
                />
            </BookingLayout>}
            />
          <Route
            path='/kiemtradonhang'
            element={<BookingLayout>
              <CheckBaoGia
                setOrderDetail={setOrderDetail}
                orderCode={orderCode}
                setType={setType}
                />
            </BookingLayout>}
            />
          <Route
            path='/kiemtradonhang/:orderCode'
            element={<BookingLayout>
              <BaoGiaDetail
                   data={orderDetail}
                   setOrderCode={setOrderCode}
                   type={type}
                />
            </BookingLayout>}
            />
          <Route
            path='/thongtinchuyendi/:contractId'
            element={<BookingLayout>
              <Thongtinchuyendi                                     
                />
            </BookingLayout>}
            /> 
          <Route
            path='/thongtinhanhtrinh/:contractcode'
            element={<BookingLayout>
              <TravelDetailSupplier
                />
            </BookingLayout>}
            />
        </Routes>
        
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
