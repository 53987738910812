import {AxiosConfig} from "../config/Axios_config";
export function addFeedback(data, callback) {
    const axios = AxiosConfig();
  
    axios
      .post(`/contract/customer/addfeedback`, data)
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        if (err.response) {
            callback(err.response.data);
        }
      });
  }