export default function Info(props) {
    const driverPlace = (driver_lat,driver_long) => {
        if (driver_lat !=0 && driver_long !=0) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${driver_lat},${driver_long}`);
        }
        else{
            alert("Không có dữ liệu vị trí xe");
        }
    }
    const updateStatus = () => {
        console.log(props.data.data.contract_id)
        console.log(props.data.data.contract_status)
        fetch(`${process.env.REACT_APP_API_SERVER_URL}/contract/updatecontractstatus?contract_id=${props.data.data.contract_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contract_status: props.data.data.contract_status,
            })
        })
        .then((res) => res.json())
        .then((data) => {
            window.location.href = window.location.href;
        })
    }
    return (
        <>
            <div className="travel-info">
                <h1 className="head-label" style={{ marginTop: '68px' }}>Thông tin hành trình</h1>
                <p className='left-label'>Liên hệ khách</p>
                <div className='text-box-pay2 note-box'>
                    <p className='left-text' style={{'font-size':'14px'}} onClick={() => window.location.href = `tel:${props.data.data.contract_phone}`}>{props.data.data.contract_name} - {props.data.data.contract_phone}</p>
                    <div className="icon-detail-special" onClick={() => window.location.href = `tel:${props.data.data.contract_phone}`} style={{ 'cursor': 'pointer','padding-bottom':'8px' }} >
                        <img src='/Image/BookingPage/Icon/call_24px.png' data-type='tel' alt="tel" />
                    </div>
                </div>
                <p className='left-label' >Thời gian đón</p>
                <div className='text-box-pay note-box'>
                    <p className='left-text' style={{'font-size':'14px'}}>{props.data.data.pickup_time}</p>
                </div>
                <p className='left-label'>Điểm đón</p>
                {props.data.data.pickup_address.map((e, idx) =>
                     
                    <div key={`pickup-${idx}`} className='text-box-pay2 note-box'>
                        <p className='left-text' style={{'font-size':'14px'}}>{e}</p>
                        <div   className="icon-detail-special" style={{'cursor':'pointer','padding-bottom':'5px'}}
                                onClick={() => driverPlace(props.data.data.lat_pickup_address[idx],props.data.data.long_pickup_address[idx])}>
                                <img src='/Image/BookingPage/Icon/place_24px_outlined.png' data-type='tel' alt="place"  />
                         </div>

                    </div> 
                )}
                <p className='left-label'>Điểm đến</p>
                {props.data.data.destination.map((e, idx) =>
                    <div key={`destination-${idx}`} className='text-box-pay2 note-box'><p className='left-text' style={{'font-size':'14px'}}>{e}</p>
                    <div   className="icon-detail-special" style={{'cursor':'pointer','padding-bottom':'5px'}} 
                            onClick={() => driverPlace(props.data.data.lat_destination[idx],props.data.data.long_destination[idx])}>
                                <img src='/Image/BookingPage/Icon/place_24px_outlined.png' data-type='tel' alt="place"  />
                         </div>
                    </div> 
                )}
                {props.data.data.contract_status === 0 &&(
                    <>
                        <button className="btn-change-status" 
                        style={{ backgroundColor: '#000000' ,color:'#fff' }}
                        onClick={updateStatus}
                        >ĐÓN KHÁCH</button>
                    </>
                )}
                {props.data.data.contract_status === 2 &&(
                    <>
                        <button className="btn-change-status" 
                        style={{ backgroundColor: '#2AB93F' ,color:'#fff' }} 
                        onClick={updateStatus}
                        >TRẢ KHÁCH</button>
                    </>
                )}
                {props.data.data.contract_status === 3 &&(
                    <>
                        <button className="btn-change-status" style={{ backgroundColor: '#FFFFFF' ,color:'#000000' }} >ĐÃ TRẢ KHÁCH</button>
                    </>
                )}
                
            </div>
        </>
    )
}