export default function Hanhtrinh(props) {
    return (
        <>
            <div className="travel-info">
                <h1 className="head-label" style={{ marginTop: '68px' }}>Hành trình</h1>
                <p className='left-label'>Thời gian đón</p>
                <div className='text-box-pay'>
                    <p className='left-text'>{props.data.data.pickup_time}</p>
                </div>
                <p className='left-label'>Điểm đón</p>
                {props.data.data.pickup_address.map((e, idx) =>
                   <div key={`pickup-${idx}`} className='text-box-pay'><p className='left-text'>{e}</p></div> 
                )}
                <p className='left-label'>Điểm đến</p>
                {props.data.data.destination.map((e, idx) =>
                    <div key={`destination-${idx}`} className='text-box-pay'><p className='left-text'>{e}</p></div> 
                )}
            </div>

        </>
    )
}